import WritableDraft, { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { v4 as uuid } from 'uuid';

import { } from 'immer'
import { stat } from 'fs';
import { RootState } from '../../../app/store';

export enum TaxType {
  OneTimeTax = 1,
  MonthlyFixedTax = 2,
  YearlyFixedTax = 3,
  MonthlyPercentageTax = 4,
}
export interface Tax {
  id: string;
  name: string;
  value: number;
  type: TaxType;
};

const colors = [
  '#003f5c',
  '#ff7c43',
  '#665191',
  '#d45087',
  '#2f4b7c',
  '#a05195',
  '#f95d6a',
  '#ffa600',
];
interface MortgageCredit {
  id: string;
  name: string;
  interestRate: number;
  taxes: Tax[];
  color: string;
}

function createNewMortgageCredit(counter: number, id: string, name: string = '', interestRate: number = 5) {
  const color = colors.shift();
  if (!name) {
    name = `Oferta credit ${counter}`;
  }
  return {
    id,
    name,
    interestRate,
    returnPerMonth: 2000,
    color,
    taxes: [{
      id: '1',
      name: 'Analiza dosar',
      value: 900,
      type: TaxType.OneTimeTax,
    },
    {
      id: '2',
      name: 'AEGRM si evaluare',
      value: 530,
      type: TaxType.OneTimeTax,
    },
    {
      id: '3',
      name: 'Radiere dosar',
      value: 250,
      type: TaxType.OneTimeTax,
    },
    {
      id: '4',
      name: 'Asigurare imobil',
      value: 500,
      type: TaxType.YearlyFixedTax,
    },
    {
      id: '5',
      name: 'Administrare cont',
      value: 5,
      type: TaxType.MonthlyFixedTax,
    },
    {
      id: '6',
      name: 'Asigurare viata',
      value: 0.2,
      type: TaxType.MonthlyPercentageTax,
    },
    ],
  } as MortgageCredit;
}

interface CommonFields {
  principal: number;
  fixedMonthlyPayments: boolean;
}

export interface CreditRange {
  start: number;
  stop: number;
  step: number;
  maxNumberOfEntires: number;
};

interface MortgageCreditState {
  commonFields: CommonFields;
  credits: MortgageCredit[];

  creditCounter: number;
  creditRange: CreditRange;
}

export type TaxChangedPayload = {
  creditId: string;
  taxId: string,
  value: number,
  name: string,
}

export type TaxAddedPayload = {
  creditId: string;
  taxId: string;
  type: TaxType;
}

export type TaxRemovedPayload = {
  creditId: string;
  taxId: string;
}

export type CreditFieldChangedPayload<T> = {
  creditId: string;
  value: T;
}

const initialState: MortgageCreditState = {
  credits: [
    createNewMortgageCredit(1, uuid(), 'Oferta 1', 3),
    createNewMortgageCredit(2, uuid(), 'Oferta 2', 5)],
  commonFields: {
    principal: 250000,
    fixedMonthlyPayments: true,
  },
  creditRange: {
    start: 1000,
    step: 200,
    stop: 15000,
    maxNumberOfEntires: 20,
  },
  creditCounter: 2,
};

export const mortgageCreditSlice = createSlice({
  name: 'mortgageCredit',
  initialState,
  reducers: {
    creditChanged: (state, action: PayloadAction<MortgageCredit>) => {
      const creditIndex = state.credits.findIndex(x => x.id === action.payload.id);
      if (creditIndex != -1) {
        state.credits[creditIndex] = action.payload;
      }
    },

    creditAdded: (state, action: PayloadAction<string>) => {
      state.creditCounter = state.creditCounter + 1;
      const newCredit = createNewMortgageCredit(state.creditCounter, action.payload);
      state.credits.push(newCredit);
    },
    creditRemoved: (state, action: PayloadAction<string>) => {
      const newCredits = state.credits.filter(x => x.id !== action.payload);
      state.credits = newCredits;
    },
    creditNameChanged: (state, action: PayloadAction<CreditFieldChangedPayload<string>>) => {
      const credit = state.credits.find(x => x.id === action.payload.creditId);
      if (credit) {
        credit.name = action.payload.value;
      }
    },
    // creditPrincipalChanged: (state, action: PayloadAction<CreditFieldChangedPayload<number>>) => {
    //   const credit = state.credits.find(x=>x.id === action.payload.creditId);
    //   if(credit){
    //     credit.principal = action.payload.value;
    //   }
    // },
    // creditReturnPerMonthChanged: (state, action: PayloadAction<CreditFieldChangedPayload<number>>) => {
    //   const credit = state.credits.find(x=>x.id === action.payload.creditId);
    //   if(credit){
    //     credit.returnPerMonth = action.payload.value;
    //   }
    // },
    creditInterestRateChanged: (state, action: PayloadAction<CreditFieldChangedPayload<number>>) => {
      const credit = state.credits.find(x => x.id === action.payload.creditId);
      if (credit) {
        credit.interestRate = action.payload.value;
      }
    },

    commonFieldsChanged: (state, action: PayloadAction<CommonFields>) => {
      state.commonFields = action.payload;
    },

    taxAdded: (state, action: PayloadAction<TaxAddedPayload>) => {
      const { creditId, taxId, type } = action.payload;
      const credit = state.credits.find(x => x.id === creditId);
      if (credit) {
        credit.taxes.push({ id: taxId, type, name: '', value: 0 });
      }
    },
    taxRemoved: (state, action: PayloadAction<TaxRemovedPayload>) => {
      const { creditId, taxId } = action.payload;
      const credit = state.credits.find(x => x.id === creditId);
      if (credit) {
        credit.taxes = credit.taxes.filter(x => x.id !== taxId);
      }
    },
    taxChanged: (state, action: PayloadAction<TaxChangedPayload>) => {
      const { creditId, taxId, value, name } = action.payload;
      const credit = state.credits.find(x => x.id === creditId);
      if (credit) {
        const tax = credit.taxes.find(x => x.id === taxId);
        if (tax) {
          tax.name = name;
          tax.value = value;
        }
      }
    },
    creditRangeChanged: (state, action: PayloadAction<CreditRange>) => {

      state.creditRange = action.payload;
    },
  },
});

export const {
  creditAdded,
  creditRemoved,
  creditChanged,
  creditNameChanged,
  // creditPrincipalChanged,
  // creditReturnPerMonthChanged,
  commonFieldsChanged,
  creditInterestRateChanged,
  taxAdded,
  taxRemoved,
  taxChanged,
  creditRangeChanged,
   } = mortgageCreditSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCreditIds = (state: RootState) => state.mortgageCredit.credits.map(x => x.id);
export const selectCreditById = (state: RootState, creditId: string) => state.mortgageCredit.credits.find(x => x.id === creditId);
export const selectTaxesByType = (state: RootState, creditId: string, type: TaxType) => {
  const credit = state.mortgageCredit.credits.find(x => x.id === creditId);
  if (credit) {
    return credit.taxes.filter(x => x.type === type);
  }
  return [];
}
export const selectCommonFields = (state: RootState) => state.mortgageCredit.commonFields;
export const selectCreditRange = (state: RootState) => state.mortgageCredit.creditRange;
export const selectOneTimeTaxes = (state: RootState, creditId: string) => selectTaxesByType(state, creditId, TaxType.OneTimeTax);
export const selectMonthlyPercentageTaxes = (state: RootState, creditId: string) => selectTaxesByType(state, creditId, TaxType.MonthlyPercentageTax);
export const selectMonthlyFixedTaxes = (state: RootState, creditId: string) => selectTaxesByType(state, creditId, TaxType.MonthlyFixedTax);
export const selectCreditsAsChart = (state: RootState) => {

  const mortgageCredit = state.mortgageCredit;

  const { principal } = mortgageCredit.commonFields;
  const { credits } = mortgageCredit;

  const numberOfYears = 10;
  const numberOfMonths = numberOfYears * 12;

  const years = Array.from(Array(numberOfYears).keys()).map(x => x + 1);
  const months = Array.from(Array(numberOfMonths).keys()).map(x => x + 1);

  const creditPaiments = [];

  for (let credit of credits) {
    const oneTimeTaxesPaiment = credit
      .taxes
      .filter(x => x.type === TaxType.OneTimeTax)
      .reduce((sum, tax) => sum + tax.value, 0)
    const monthlyFixedTaxes = credit.taxes.filter(x => x.type === TaxType.MonthlyFixedTax);
    const appliedMonthlyFixedTaxes = monthlyFixedTaxes.reduce((sum, tax) => sum + tax.value, 0);

    const monthlyPercentageTaxes = credit.taxes.filter(x => x.type === TaxType.MonthlyPercentageTax);
    const monthlyPercentageTaxesSum = monthlyPercentageTaxes.reduce((sum, tax) => sum + tax.value, 0);

    const yearlyFixedTaxes = credit.taxes.filter(x => x.type === TaxType.YearlyFixedTax);
    const yearlyFixedTaxesSum = yearlyFixedTaxes.reduce((sum, tax) => sum + tax.value, 0);

    const monthlyInterestRate = credit.interestRate / 12;

    const payments = [];
    for (let year of years) {
      const yearsFixedTaxesPaimens = yearlyFixedTaxesSum * year;

      let paid = oneTimeTaxesPaiment + yearsFixedTaxesPaimens;
      let remainingPrincipal = principal;
      const numberOfMonths = year * 12;
      const months = Array.from(Array(numberOfMonths).keys()).map(x => x + 1);
      const returnPerMonth = principal / numberOfMonths;

      for (let month of months) {
        const appliedMonthlyPercentageTaxes = remainingPrincipal * (monthlyInterestRate + monthlyPercentageTaxesSum);
        paid = paid + appliedMonthlyPercentageTaxes + appliedMonthlyFixedTaxes;
        remainingPrincipal = Math.max(remainingPrincipal - returnPerMonth, 0);
        if (remainingPrincipal === 0) {
          break;
        }
      }
      const paymentsInYears = {
        x: year,
        y: paid,
      }
      payments.push(paymentsInYears);
    }
    const creditData = {
      payments: payments,
      name: credit.name,
      id: credit.id,
    };
    creditPaiments.push(creditData);
  }
  return creditPaiments;
};

export const selectCreditsAsVariableReturnRateChart = (state: RootState) => {
  const { mortgageCredit } = state;

  const { principal } = mortgageCredit.commonFields;
  const { credits, creditRange } = mortgageCredit;

  const numberOfSteps = (creditRange.stop - creditRange.start) / creditRange.step;
  const returns = Array.from(Array(numberOfSteps).keys()).map(x => x * creditRange.step);
  const creditPaiments = [];

  for (let credit of credits) {
    const oneTimeTaxesPaiment = credit
      .taxes
      .filter(x => x.type === TaxType.OneTimeTax)
      .reduce((sum, tax) => sum + tax.value, 0);

    const monthlyFixedTaxes = credit.taxes.filter(x => x.type === TaxType.MonthlyFixedTax);
    const appliedMonthlyFixedTaxes = monthlyFixedTaxes.reduce((sum, tax) => sum + tax.value, 0);

    const monthlyPercentageTaxes = credit.taxes.filter(x => x.type === TaxType.MonthlyPercentageTax);
    const monthlyPercentageTaxesSum = monthlyPercentageTaxes.reduce((sum, tax) => sum + tax.value, 0);

    const yearlyFixedTaxes = credit.taxes.filter(x => x.type === TaxType.YearlyFixedTax);
    const yearlyFixedTaxesSum = yearlyFixedTaxes.reduce((sum, tax) => sum + tax.value, 0);

    const monthlyInterestRate = credit.interestRate / 12;

    const payments = [];
    let numberOfMonthsToPay = 0;
    for (let returnPerMonth of returns) {
      let remainingPrincipal = principal;
      let paid = oneTimeTaxesPaiment;

      while (remainingPrincipal > 0) {

        if (numberOfMonthsToPay % 12 === 0) {
          let yearlyAppliedTaxes = yearlyFixedTaxesSum;
          paid = paid + yearlyAppliedTaxes;
        }

        const appliedMonthlyPercentageTaxes = remainingPrincipal * (monthlyInterestRate + monthlyPercentageTaxesSum);
        paid = paid + appliedMonthlyPercentageTaxes + appliedMonthlyFixedTaxes;
        numberOfMonthsToPay++;
        remainingPrincipal = Math.max(remainingPrincipal - returnPerMonth, 0);
      }
      const paymentsInYears = {
        x: returnPerMonth,
        y: paid,
      };
      payments.push(paymentsInYears);
    }

    const creditData = {
      payments: payments,
      name: credit.name,
      id: credit.id,
    };
    creditPaiments.push(creditData);
  }
  return creditPaiments;
};



export default mortgageCreditSlice.reducer;
