import React from "react";
import { Provider } from "react-redux";
import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";
import GlobalStyles from '../../components/atoms/GlobalStyles';
import { store } from "../../app/store";

export default function TopLayout({ children, theme }) {

  return (
    <Provider store={store}>
      <ThemeTopLayout theme={theme}>
        <GlobalStyles />
        {children}
      </ThemeTopLayout>
    </Provider>
  );
}
