import { createMuiTheme, colors } from '@material-ui/core';
import palete from './palete';
import shadows from './shadows';
import typography from './typography';

const {background, primary, secondary, text} = palete;

const theme = createMuiTheme({
  palette: {
    background,
    primary,
    secondary,
    text,
    
  },
  overrides: {
    // Style sheet name ⚛️
    MuiLink: {
      // Name of the rule
    },
  },
  shadows,
  typography
});

export default theme;