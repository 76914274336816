// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-blog-template-tsx": () => import("./../../../src/components/templates/blog-template.tsx" /* webpackChunkName: "component---src-components-templates-blog-template-tsx" */),
  "component---src-components-templates-policy-template-tsx": () => import("./../../../src/components/templates/policy-template.tsx" /* webpackChunkName: "component---src-components-templates-policy-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-credit-comparator-tsx": () => import("./../../../src/pages/credit-comparator.tsx" /* webpackChunkName: "component---src-pages-credit-comparator-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

