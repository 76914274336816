import { colors } from "@material-ui/core";

const palete = {
    background: {
        content: "#F4F6F8",
        default: colors.common.white,
        paper: colors.common.white,
    },
    primary: {
        main: "#03a9f4",
        light: "#67daff",
        dark: "#007ac1",
    },
    secondary: {
        main: "#ffc107",
        light: "#fff350",
        dark: "#c79100",
    },
    text: {
        primary: "#212121",
        secondary: "#757575"
    },
};

export default palete;